import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import Cookies from "js-cookie";
import { Cards } from "./Cards";
import { Result } from "./Result";
import { SideNavigation } from "../Sidebar/Navigation";
import {
	faDroplet,
	faHeartPulse,
	faTemperatureHalf,
} from "@fortawesome/free-solid-svg-icons";
import "./Dashboard.css";

const socket = io.connect(`${process.env.REACT_APP_SERVER_IP}:2083`, {
	withCredentials: true,
});

export const RealTime = () => {
	let today = new Date();
	let day = `${today.getDate() < 10 ? "0" : ""}${today.getDate()}`;
	let month = `${today.getMonth() + 1 < 10 ? "0" : ""}${today.getMonth() + 1}`;
	let year = `${today.getFullYear()}`;
	
	const [clock, setClock] = useState("");
	const [bpm, setBPM] = useState([]);
	const [spo2, setSpO2] = useState([]);
	const [time, setTime] = useState([]);
	const [temp, setTemp] = useState([]);
	const [second, setSecond] = useState([]);
	const [timeNow, setTimeNow] = useState(-1);
	
	useEffect(() => {
		socket.on("postData", (data) => {
			const userID = Number(Cookies.get("userID"));
			if (data.hardwareID === userID) {
				let h = `${
					today.getHours() < 10 ? "0" : ""
				}${today.getHours()}`;
				let m = `${
					today.getMinutes() < 10 ? "0" : ""
				}${today.getMinutes()}`;
				let s = `${
					today.getSeconds() < 10 ? "0" : ""
				}${today.getSeconds()}`;
				let t = `${h}:${m}:${s}`;

				if (data) {
					setTime([...time, t]);
					setTemp([...temp, data.temp.toFixed(1)]);
					setBPM([...bpm, data.bpm]);
					setSpO2([...spo2, data.spo2]);
					setSecond([...second, s]);
					setTimeNow(today.getSeconds());
				}
			}
		});
		setInterval(() => {
			const clock = new Date();
			setClock(clock.toLocaleTimeString("en-GB"));
		}, 1000);
	}, [bpm, second, spo2, temp, time, timeNow]);

	function returnData(data) {
		if (data.length > 10) {
			return data.slice(data.length - 10);
		} else return data;
	}
	const data = {
		bpm: {
			labels: returnData(second),
			datasets: [
				{
					backgroundColor: "rgba(204, 73, 33, 0.8)",
					borderColor: "rgb(204, 73, 33)",
					data: returnData(bpm),
				},
			],
		},
		spo2: {
			labels: returnData(second),
			datasets: [
				{
					backgroundColor: "rgb(230,0,0,0.7)",
					borderColor: "rgb(230,0,0)",
					data: returnData(spo2),
				},
			],
		},
		temp: {
			labels: returnData(second),
			datasets: [
				{
					backgroundColor: "rgba(236,109,8,0.7)",
					borderColor: "rgb(236,109,8)",
					data: returnData(temp),
				},
			],
		},
	};

	function getMaximumRange(data) {
		if (data.length !== 0) {
			return Math.max(...data);
		} else {
			return 30;
		}
	}
	function getMinimumRange(data) {
		if (data.length !== 0) {
			return Math.min(...data);
		} else {
			return 20;
		}
	}
	function spo2Unit() {
		return (
			<span>
				%SpO<sub>2</sub>
			</span>
		);
	}
	return (
		<div className="dashboard d-flex">
			<div>
				<SideNavigation></SideNavigation>
			</div>
			<div
				style={{
					flex: "1 1 auto",
					display: "flex",
					flexFlow: "column",
					height: "100vh",
					overflowY: "hidden",
				}}
			>
				<div style={{ height: "100%" }}>
					<div style={{ height: "100%", overflowY: "auto" }}>
						<div className="d-flex card-section flex-column pt-4">
							{!clock ? (
								<div className="spinner-border" role="status">
									<span className="visually-hidden">
										Loading...
									</span>
								</div>
							) : (
								<p className="h4 mb-0">
									{day}/{month}/{year} {clock}
								</p>
							)}
							<div className="cards-container">
								<Cards
									name="Oxygen Saturation"
									data={data.spo2}
									average={
										spo2.length === 0
											? 0
											: spo2[spo2.length - 1]
									}
									max={getMaximumRange(spo2)}
									min={getMinimumRange(spo2)}
									stepSize="1"
									measurement={spo2Unit()}
									icon={faDroplet}
									color="#660000"
								/>
								<Cards
									name="Heart Rate"
									data={data.bpm}
									average={
										bpm.length === 0
											? 0
											: bpm[bpm.length - 1]
									}
									max={getMaximumRange(bpm)}
									min={getMinimumRange(bpm)}
									stepSize="1"
									measurement="BPM"
									icon={faHeartPulse}
									color="#cc0000"
								/>
								<Cards
									name="Body Temperature"
									data={data.temp}
									average={
										temp.length === 0
											? 0
											: temp[temp.length - 1]
									}
									max={getMaximumRange(temp)}
									min={getMinimumRange(temp)}
									stepSize="0.1"
									measurement="°C"
									icon={faTemperatureHalf}
									color="#cc6600"
								/>
							</div>
							<Result
								time={time}
								bpm={bpm}
								spo2={spo2}
								temp={temp}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
