import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import axios from "axios";
import Cookies from "js-cookie";
import Maps from "./Maps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationCrosshairs } from "@fortawesome/free-solid-svg-icons";
import "./Home.css";

const socket = io.connect(`${process.env.REACT_APP_SERVER_IP}:2083`, {
	withCredentials: true,
});

export const Location = () => {
	const [gpsTime, setGPSTime] = useState("");
	const [disconnected, setDisconnected] = useState(true);
	const [lat, setLat] = useState("");
	const [lng, setLng] = useState("");

	function checkConnectivity() {
		if (lng === "" || lat === "") {
			setDisconnected(true);
		}
	}

	async function getGPS() {
		const userID = Number(Cookies.get("userID"));
		if (lng === "" || lat === "") {
			const response = await axios.get(
				`${process.env.REACT_APP_SERVER_IP}/api/getGPS/${userID}`
			);
			setLat(response.data.latitude);
			setLng(response.data.longitude);
			setGPSTime(response.data.updatedDateTime);
			setDisconnected(true);
		}
	}

	useEffect(() => {
		checkConnectivity();
		getGPS();
		socket.on("postData", (data) => {
			const userID = Number(Cookies.get("userID"));
			if (data.hardwareID === userID) {
				if (
					data.lat &&
					data.lng &&
					data.lat !== 0 &&
					data.lng !== 0
				) {
					setLat(data.lat);
					setLng(data.lng);
					setGPSTime(data.dateTime);
					setDisconnected(false);					
				} else {
					getGPS();
				}
			}
		});
	}, []);

	return (
		<div
			className="card-bg w-100 border d-flex flex-column p-4"
			style={{ gridRow: "span 1", height: "100%", width: "100vw" }}
		>
			<div className="d-flex flex-row justify-content-between">
				<div className="text-end text-dark fw-bolder card-title">
					Location Status
				</div>
				<FontAwesomeIcon
					icon={faLocationCrosshairs}
					size="2xl"
					color="blue"
					className="my-auto"
				/>
			</div>
			<div style={{ width: "100%", height: "32vh" }} className="mt-2">
				<Maps lat={lat} lng={lng} disconnected={disconnected} />
			</div>
			{disconnected ? (
				<div className="card-subtitle mt-2">
					Location is&nbsp;
					<span className="text-decoration-underline">
						NOT DETECTED
					</span>
					<div>
						Last updated :&nbsp;
						<span className="fw-bolder">{gpsTime}</span>
					</div>
				</div>
			) : (
				<div className="card-subtitle mt-2">
					Location is&nbsp;
					<span className="text-decoration-underline">DETECTED</span>
					<div>
						Last updated :&nbsp;
						<span className="fw-bolder">{gpsTime}</span>
					</div>
				</div>
			)}
		</div>
	);
};
