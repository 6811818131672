import React from "react";
import SignUp from "./SignUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../App.css";

export const SignInForm = (props) => {
	const {
		setUsername,
		passwordShown,
		setPassword,
		togglePassword,
		eyeSymbol,
		password,
		handleSubmit,
		wrongPassword,
		currentWrongPassword,
	} = props;
	return (
		<div>
			<p className="fw-bolder text-dark h3 mb-4 mt-5 pt-3 title">
				In-SWALST
			</p>
			<p className="pt-3 fw-bolder text-dark h4 mb-4 title">
				IoT-Based Smart Walking Stick
			</p>
			<small className="text-muted">
				Welcome! Enter your credentials to sign in.
			</small>
			<div className="mt-4">
				<input
					type="text"
					onChange={(e) => setUsername(e.target.value)}
					className="form-control ms-1 mb-1"
					placeholder="Username"
				/>
				<div className="input-group ms-1">
					<input
						type={passwordShown ? "text" : "password"}
						onChange={(e) => setPassword(e.target.value)}
						className="form-control"
						placeholder="Password"
					/>
					<button
						className="btn btn-primary px-3"
						type="button"
						onClick={togglePassword}
					>
						<FontAwesomeIcon icon={eyeSymbol} />
					</button>
				</div>
				{wrongPassword === true && currentWrongPassword === password ? (
					<label
						htmlFor="password"
						className="m-0 p-0 ms-2 text-danger"
						style={{ fontSize: "12px" }}
					>
						Incorrect Password or Username
					</label>
				) : (
					""
				)}
				<div className="flex-row d-flex mt-3">
					<button
						type="button"
						className="btn btn-primary ms-1 mt-3 fw-bolder"
						onClick={handleSubmit}
					>
						Sign In
					</button>
					<button
						type="button"
						className="btn btn-primary mx-4 mt-3 fw-bolder"
						data-bs-toggle="modal"
						data-bs-target="#exampleModal"
					>
						Sign Up
					</button>
					<SignUp />
				</div>
			</div>
		</div>
	);
};
