import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import {
	DatatableWrapper,
	Pagination,
	PaginationOptions,
	TableBody,
	TableHeader,
} from "react-bs-datatable";
import { Col, Row, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export const HistoryContent = () => {
	const [selectedDate, setSelectedDate] = useState("");
	const [data, setData] = useState([]);

	function oxygenSaturationTitle() {
		return (
			<span>
				Oxygen Saturation (%SpO<sub>2</sub>)
			</span>
		);
	}

	const customLabels = {
		firstPage: "<<",
		lastPage: ">>",
		prevPage: "<",
		nextPage: ">",
	};

	const headers = [
		{ title: "Date", prop: "date", isFilterable: true, isSortable: true },
		{ title: "Time", prop: "time", isSortable: true },
		{ title: oxygenSaturationTitle(), prop: "spo2", isSortable: true },
		{ title: "Heart Rate (BPM)", prop: "bpm", isSortable: true },
		{ title: "Body Temperature (°C)", prop: "temp", isSortable: true },
	];

	async function getHistory() {
		const userID = Number(Cookies.get("userID"));
		const response = await axios.get(
			`${process.env.REACT_APP_SERVER_IP}/api/getHistory/${userID}`
		);
		const formattedData = response.data.map((item) => ({
			...item,
			date: item.date.split("T")[0],
		}));
		setData(formattedData);
	}

	useEffect(() => {
		getHistory();
	}, []);

	const formatDate = (dateStr) => {
		const date = new Date(dateStr);
		const day = String(date.getDate()).padStart(2, "0");
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const year = date.getFullYear();
		return `${day}/${month}/${year}`;
	};

	const filterData = (data, selectedDate) => {
		if (!selectedDate) return data;
		const formattedSelectedDate = selectedDate.split("T")[0];
		return data.filter((item) => item.date === formattedSelectedDate);
	};

	const filteredData = filterData(data, selectedDate).map((item) => ({
		...item,
		date: formatDate(item.date),
	}));

	return (
		<div className="card-bg border py-2 p-4" style={{ gridRow: "span 1" }}>
			<div className="d-flex flex-column text-center">
				<DatatableWrapper
					body={filteredData}
					headers={headers}
					paginationOptionsProps={{
						initialState: {
							rowsPerPage: 10,
							options: [5, 10, 15, 20],
						},
					}}
				>
					<Row className="mb-4">
						<Col
							xs={12}
							lg={4}
							className="d-flex flex-column justify-content-end align-items-start"
						>
							<input
								className="form-control"
								type="date"
								value={selectedDate}
								style={{ width: "175px" }}
								onChange={(e) => {
									setSelectedDate(e.target.value);
								}}
							/>
						</Col>
						<Col
							xs={12}
							sm={6}
							lg={4}
							className="d-flex flex-column justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
						>
							<PaginationOptions />
						</Col>
						<Col
							xs={12}
							sm={6}
							lg={4}
							className="d-flex flex-column justify-content-end align-items-end"
						>
							<Pagination
								labels={customLabels}
							/>
						</Col>
					</Row>
					<Table>
						<TableHeader />
						<TableBody />
					</Table>
				</DatatableWrapper>
			</div>
		</div>
	);
};