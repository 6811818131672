import React from "react";
import { HistoryContent } from "./HistoryContent";
import { SideNavigation } from "../Sidebar/Navigation";

export const History = () => {
	return (
		<div className="dashboard d-flex">
			<div>
				<SideNavigation></SideNavigation>
			</div>
			<div
				style={{
					flex: "1 1 auto",
					display: "flex",
					flexFlow: "column",
					height: "100vh",
					overflowY: "hidden",
				}}
			>
				<div style={{ height: "100%" }}>
					<div style={{ height: "100%", overflowY: "auto" }}>
						<div className="d-flex flex-column px-4 pt-4">
							<HistoryContent />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
