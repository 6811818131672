import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
import { ToastContainer, toast } from "react-toastify";
import ReactPhoneInput from "react-phone-input-2";
import Select from "react-select";
import countryList from "react-select-country-list";
import { SideNavigation } from "../Sidebar/Navigation";
import "chart.js/auto";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCamera,
	faFloppyDisk,
	faPencil,
} from "@fortawesome/free-solid-svg-icons";
import "./Profile.css";
import "../../App.css";

export const Profile = () => {
	const [fullName, setFullName] = useState("");
	const [nickname, setNickname] = useState("");
	const [citizenship, setCitizenship] = useState("");
	const [dateOfBirth, setDateOfBirth] = useState("");
	const [userID, setUserID] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [email, setEmail] = useState("");
	const [profilePicture, setProfilePicture] = useState("");
	const [updateMode, setUpdateMode] = useState(false);

	const options = useMemo(() => countryList().getData(), []);

	async function updatePicture(files) {
		const userID = Number(Cookies.get("userID"));
		fetch(`${process.env.REACT_APP_SERVER_IP}/api/updateProfilePicture`, {
			headers: {
				"Content-Type": "application/json",
			},
			method: "PUT",
			body: JSON.stringify({
				profilePicture: files[0]?.fileUrl,
				userID: userID,
			}),
		}).then(() => {
			toast.success("Photo updated successfully");
			setUpdateMode(false);
			getUserData();
		});
	}

	async function getUserData() {
		const userID = Number(Cookies.get("userID"));
		const response = await axios.get(
			`${process.env.REACT_APP_SERVER_IP}/api/getUserID/${userID}`
		);
		const data = response.data.data;
		setFullName(data?.fullName);
		setNickname(data.nickname);
		setCitizenship(
			options.find((option) => option.label === data.citizenship)
		);
		setDateOfBirth(data.dateOfBirth);
		setUserID(data.userID);
		setPhoneNumber(data.phoneNumber);
		setEmail(data.email);
		setProfilePicture(data?.profilePicture);
	}

	function caps(fullName) {
		return fullName?.charAt(0).toUpperCase() + fullName?.slice(1);
	}

	async function save() {
		fetch(`${process.env.REACT_APP_SERVER_IP}/api/updateProfile`, {
			headers: {
				"Content-Type": "application/json",
			},
			method: "PUT",
			body: JSON.stringify({
				fullName: fullName,
				nickname: nickname,
				citizenship: citizenship.label,
				dateOfBirth: dateOfBirth,
				userID: userID,
				phoneNumber: phoneNumber,
				email: email,
			}),
		}).then(() => {
			toast.success("Profile updated successfully");
			setUpdateMode(false);
		});
	}

	const uploader = Uploader({
		apiKey: "free",
	});

	useEffect(() => {
		getUserData();
	}, []);

	return (
		<div className="dashboard d-flex">
			<ToastContainer />
			<div>
				<SideNavigation />
			</div>
			<div
				style={{
					flex: "1 1 auto",
					display: "flex",
					flexFlow: "column",
					height: "100vh",
					overflowY: "hidden",
				}}
			>
				<div style={{ height: "100%" }}>
					<div style={{ height: "100%", overflowY: "auto" }}>
						<div className="d-flex card-section-main">
							<div className="cards-container-main">
								<div
									className="card-bg w-100 d-flex flex-column border"
									style={{ gridColumn: "span 2" }}
								>
									<div className="p-3 d-flex flex-column h-100">
										<div className="d-flex align-items-center justify-content-between">
											<p className="h2 m-0 py-4 ms-5 fw-bolder text-dark text-decoration-underline profile-heading">
												Profile
											</p>
											<button
												type="button"
												className="btn btn-primary fw-bolder me-5"
												onClick={() =>
													setUpdateMode(!updateMode)
												}
											>
												<span className="pe-2 my-auto">
													Edit
												</span>
												<FontAwesomeIcon
													icon={faPencil}
													className="my-auto"
												/>
											</button>
										</div>
										<div className="container-fluid">
											<div className="row">
												<div className="col-md-2 text-center px-5">
													{!profilePicture ||
													profilePicture ===
														"undefined" ? (
														<svg
															className="img-fluid img-thumbnail rounded p-2"
															style={{
																width: "200px",
															}}
															xmlns="https://www.w3.org/TR/SVG2"
															viewBox="0 0 448 512"
														>
															<path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
														</svg>
													) : (
														<img
															src={profilePicture}
															alt=""
															className="img-fluid img-thumbnail rounded p-2"
															style={{
																width: "200px",
															}}
														/>
													)}
													<div className="text-center d-flex flex-row justify-content-center">
														{!updateMode ? (
															""
														) : (
															<UploadButton
																uploader={
																	uploader
																}
																options={{
																	multi: true,
																}}
																onComplete={(
																	files
																) =>
																	updatePicture(
																		files
																	)
																}
															>
																{({
																	onClick,
																}) => (
																	<div className="d-flex profile-subtitle mt-3">
																		<button
																			type="button"
																			onClick={
																				onClick
																			}
																			className="btn btn-primary d-flex flex-row my-auto fw-bolder"
																		>
																			<span className="pe-2 my-auto">
																				Change
																				Photo
																			</span>
																			<FontAwesomeIcon
																				icon={
																					faCamera
																				}
																				className="my-auto"
																			/>
																		</button>
																	</div>
																)}
															</UploadButton>
														)}
													</div>
													<p className="mt-3 h6 text-center fw-bolder">
														{userID}
													</p>
												</div>
												<div className="col-md-5 padding-col-potrait">
													<p className="fw-bolder h6 profile-title">
														Full Name
													</p>
													<hr></hr>
													{updateMode === false ? (
														<div className="">
															{caps(fullName)}
														</div>
													) : (
														<input
															value={caps(
																fullName
															)}
															className="form-control"
															onChange={(e) =>
																setFullName(
																	e.target
																		.value
																)
															}
														/>
													)}
													<p className="fw-bolder h6 mt-5 profile-title">
														Nickname
													</p>
													<hr></hr>
													{updateMode === false ? (
														<div className="">
															{caps(nickname)}
														</div>
													) : (
														<input
															value={caps(
																nickname
															)}
															className="form-control"
															onChange={(e) =>
																setNickname(
																	e.target
																		.value
																)
															}
														/>
													)}
													<p className="fw-bolder h6 mt-5 profile-title">
														Citizenship
													</p>
													<hr></hr>
													{updateMode === false ? (
														<div className="mt">
															{citizenship.label}
														</div>
													) : (
														<Select
															options={options}
															value={citizenship}
															onChange={
																setCitizenship
															}
														/>
													)}
												</div>
												<div className="col-md-5 padding-col-potrait">
													<p className="fw-bolder h6 profile-title">
														Date of Birth
													</p>
													<hr></hr>
													{updateMode === false ? (
														<div className="mt">
															{dateOfBirth}
														</div>
													) : (
														<input
															value={dateOfBirth}
															className="form-control"
															type="date"
															onChange={(e) =>
																setDateOfBirth(
																	e.target
																		.value
																)
															}
														/>
													)}
													<p className="fw-bolder h6 mt-5 profile-title">
														Email
													</p>
													<hr></hr>
													{updateMode === false ? (
														<div className="mt">
															{email}
														</div>
													) : (
														<input
															value={email}
															onChange={(e) =>
																setEmail(
																	e.target
																		.value
																)
															}
															className="form-control"
															type="email"
														/>
													)}
													<p className="fw-bolder h6 mt-5 profile-title">
														Phone Number
													</p>
													<hr></hr>
													{updateMode === false ? (
														<div className="mt">
															{"(+" +
																phoneNumber?.slice(
																	0,
																	2
																) +
																")" +
																" " +
																phoneNumber.slice(
																	2
																)}
														</div>
													) : (
														<ReactPhoneInput
															inputStyle={{
																width: "100%",
															}}
															enableSearch
															country="id"
															value={phoneNumber}
															onChange={
																setPhoneNumber
															}
														/>
													)}
												</div>
											</div>
											<div className="d-flex flex-row justify-content-end mt-5">
												{updateMode ? (
													<button
														type="button"
														className="mt-3 btn btn-primary fw-bolder"
														onClick={save}
													>
														<span className="pe-2 my-auto">
															Save
														</span>
														<FontAwesomeIcon
															icon={faFloppyDisk}
															className="my-auto"
														/>
													</button>
												) : (
													""
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
