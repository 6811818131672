import React from "react";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/bootstrap.css";

export const SignUpForm2 = (props) => {
	const {
		nationalIDNumber,
		familyCardNumber,
		phoneNumber,
		email,
		setEmail,
		setPhoneNumber,
		checkNationalIDNumber,
		validNationalIDNumber,
		validFeedbackNationalIDNumber,
		checkFamilyCardNumber,
		validFamilyCardNumber,
		validFeedbackFamilyCardNumber,
	} = props;
	return (
		<div>
			<div className="row">
				<div className="col">
					<div className="mb-3">
						<label htmlFor="floatingInput">
							National ID Number
						</label>
						<input
							placeholder="National ID Number"
							type="number"
							className={validNationalIDNumber}
							onChange={(e) =>
								checkNationalIDNumber(e.target.value)
							}
							defaultValue={nationalIDNumber}
						/>
						<div className={validFeedbackNationalIDNumber}>
							{validFeedbackNationalIDNumber === "valid-feedback"
								? ""
								: "It needs to contain 16 numbers"}
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<div className="mb-3">
						<label htmlFor="floatingInput">
							Family Card Number
						</label>
						<input
							placeholder="Family Card Number"
							type="number"
							className={validFamilyCardNumber}
							onChange={(e) =>
								checkFamilyCardNumber(e.target.value)
							}
							defaultValue={familyCardNumber}
						/>
						<div className={validFeedbackFamilyCardNumber}>
							{validFeedbackFamilyCardNumber === "valid-feedback"
								? ""
								: "It needs to contain 16 numbers"}
						</div>
					</div>
				</div>
			</div>
			<div className="col">
				<div className="mb-3">
					<label htmlFor="floatingInput">Email</label>
					<input
						type="email"
						className="form-control"
						placeholder="username@example.com"
						defaultValue={email}
						onChange={(e) => {
							setEmail(e.target.value);
						}}
					/>
				</div>
			</div>
			<div className="col">
				<div className="mb-3">
					<label htmlFor="floatingInput">Phone Number</label>
					<ReactPhoneInput
						inputStyle={{ width: "100%" }}
						enableSearch
						country="id"
						value={phoneNumber}
						onChange={setPhoneNumber}
					/>
				</div>
			</div>
		</div>
	);
};
