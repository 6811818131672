import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SignIn } from "./Components/SignIn/SignIn";
import { History } from "./Components/History/History";
import { Home } from "./Components/Home/Home";
import { Profile } from "./Components/Profile/Profile";
import { Security } from "./Components/Security/Security";
import { About } from "./Components/About/About";
import { Location } from "./Components/Location/Location";
import { RealTime } from "./Components/RealTime/RealTime";
import { PageNotFound } from "./Components/ErrorPage/404";

export default function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<SignIn />} />
				<Route path="/home" element={<Home />} />
				<Route path="/profile" element={<Profile />} />
				<Route path="/realtime" element={<RealTime />} />
				<Route path="/history" element={<History />} />
				<Route path="/security" element={<Security />} />
				<Route path="/location" element={<Location />} />
				<Route path="/about" element={<About />} />
				<Route path="*" element={<PageNotFound />} />
			</Routes>
		</BrowserRouter>
	);
}
