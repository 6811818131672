import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { SignUpForm1 } from "./SignUpForm1";
import { SignUpForm2 } from "./SignUpForm2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCircleLeft,
	faCircleRight,
	faPaperPlane,
	faEye,
	faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import "../../App.css";

export default function SignUp() {
	const [fullName, setFullName] = useState("");
	const [nickname, setNickname] = useState("");
	const [citizenship, setCitizenship] = useState("");
	const [dateOfBirth, setDateOfBirth] = useState("");
	const [username, setUsername] = useState("");
	const [hardwareID, setHardwareID] = useState("");
	const [password, setPassword] = useState("");
	const [email, setEmail] = useState("");
	const [nationalIDNumber, setNationalIDNumber] = useState("");
	const [familyCardNumber, setFamilyCardNumber] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [disabled, setDisabled] = useState(true);
	const [submitDisabled, setSubmitDisabled] = useState(true);
	const [validUsername, setValidUsername] = useState("form-control");
	const [validHardwareID, setValidHardwareID] = useState("form-control");
	const [validNationalIDNumber, setValidNationalIDNumber] =
		useState("form-control");
	const [validFamilyCardNumber, setValidFamilyCardNumber] =
		useState("form-control");
	const [validFeedbackUsername, setValidFeedbackUsername] =
		useState("valid-feedback");
	const [validFeedbackHardwareID, setValidFeedbackHardwareID] =
		useState("valid-feedback");
	const [validFeedbackNationalIDNumber, setValidFeedbackNationalIDNumber] =
		useState("valid-feedback");
	const [validFeedbackFamilyCardNumber, setValidFeedbackFamilyCardNumber] =
		useState("valid-feedback");
	const [render, setRender] = useState(false);
	const [passwordShownModal, setPasswordShownModal] = useState(false);
	const [eyeSymbolModal, setEyeSymbolModal] = useState(faEyeSlash);

	function checkNextDisable() {
		if (
			fullName.length !== 0 &&
			password.length !== 0 &&
			citizenship.length !== 0 &&
			dateOfBirth.length !== 0 &&
			username.length !== 0 &&
			nickname.length !== 0 &&
			hardwareID.length !== 0 &&
			validUsername === "form-control is-valid" &&
			validHardwareID === "form-control is-valid"
		) {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	}

	function checkSubmitDisable() {
		if (
			nationalIDNumber.length !== 0 &&
			email.length !== 0 &&
			familyCardNumber.length !== 0 &&
			phoneNumber.length !== 0 &&
			validNationalIDNumber === "form-control is-valid" &&
			validFamilyCardNumber === "form-control is-valid"
		) {
			setSubmitDisabled(false);
		} else {
			setSubmitDisabled(true);
		}
	}
	const togglePasswordModal = () => {
		setPasswordShownModal(!passwordShownModal);
		if (eyeSymbolModal === faEye) {
			setEyeSymbolModal(faEyeSlash);
		} else if (eyeSymbolModal === faEyeSlash) {
			setEyeSymbolModal(faEye);
		}
	};
	async function checkUsername(username) {
		setUsername(username);
		if (username !== undefined) {
			if (username.length > 0) {
				const response = await axios.get(
					`${process.env.REACT_APP_SERVER_IP}/api/getUsername/${username}`
				);
				if (response.data.valid === true) {
					setValidUsername("form-control is-valid");
					setValidFeedbackUsername("valid-feedback");
				} else {
					setValidUsername("form-control is-invalid");
					setValidFeedbackUsername("invalid-feedback");
				}
			} else if (username.length === 0) {
				setValidUsername("form-control");
			}
		}
	}
	async function checkHardwareID(hardwareID) {
		setHardwareID(hardwareID);
		if (hardwareID !== undefined) {
			if (hardwareID.length > 0) {
				const response = await axios.get(
					`${process.env.REACT_APP_SERVER_IP}/api/getHardwareID/${hardwareID}`
				);
				if (response.data.valid === true) {
					setValidHardwareID("form-control is-valid");
					setValidFeedbackHardwareID("valid-feedback");
				} else {
					setValidHardwareID("form-control is-invalid");
					setValidFeedbackHardwareID("invalid-feedback");
				}
			} else if (hardwareID.length === 0) {
				setValidHardwareID("form-control");
			}
		}
	}
	async function checkFamilyCardNumber(familyCardNumber) {
		setFamilyCardNumber(familyCardNumber);
		if (familyCardNumber.length > 0) {
			if (familyCardNumber.length === 16) {
				setValidFamilyCardNumber("form-control is-valid");
				setValidFeedbackFamilyCardNumber("valid-feedback");
			} else {
				setValidFamilyCardNumber("form-control is-invalid");
				setValidFeedbackFamilyCardNumber("invalid-feedback");
			}
		} else if (familyCardNumber.length === 0) {
			setValidFamilyCardNumber("form-control");
		}
	}
	async function checkNationalIDNumber(nationalIDNumber) {
		setNationalIDNumber(nationalIDNumber);
		if (nationalIDNumber.length > 0) {
			if (nationalIDNumber.length === 16) {
				setValidNationalIDNumber("form-control is-valid");
				setValidFeedbackNationalIDNumber("valid-feedback");
			} else {
				setValidNationalIDNumber("form-control is-invalid");
				setValidFeedbackNationalIDNumber("invalid-feedback");
			}
		} else if (nationalIDNumber.length === 0) {
			setValidNationalIDNumber("form-control");
		}
	}
	function signUp() {
		axios
			.post(`${process.env.REACT_APP_SERVER_IP}/api/signUp`, {
				fullName: fullName,
				nickname: nickname,
				citizenship: citizenship.label,
				dateOfBirth: dateOfBirth,
				username: username,
				password: password,
				userID: hardwareID,
				nationalIDNumber: nationalIDNumber,
				familyCardNumber: familyCardNumber,
				phoneNumber: phoneNumber,
				email: email,
			})
			.then((response) => {
				if (response.error === true) {
					toast.success("Something went wrong");
				} else {
					toast.success("You have been registered");
				}
			});
	}

	useEffect(() => {
		checkNextDisable();
		checkSubmitDisable();
	});

	return (
		<div
			className="modal fade"
			id="exampleModal"
			tabIndex="-1"
			role="dialog"
			aria-labelledby="exampleModal"
			aria-hidden="true"
		>
			<div
				className="modal-dialog modal-dialog-centered modal-lg"
				role="document"
			>
				<div className="modal-content">
					<div className="modal-header">
						<p
							className="h3 modal-title"
							id="exampleModalLongTitle"
						>
							Sign Up
						</p>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>
					<p className="h5 ms-4 mt-2 mb-0 text-decoration-underline">
						Credentials
					</p>
					<div className="modal-body container px-5">
						{render === false && (
							<SignUpForm1
								setFullName={setFullName}
								setNickname={setNickname}
								setCitizenship={setCitizenship}
								setDateOfBirth={setDateOfBirth}
								checkUsername={checkUsername}
								checkHardwareID={checkHardwareID}
								setPassword={setPassword}
								validFeedbackUsername={validFeedbackUsername}
								validFeedbackHardwareID={
									validFeedbackHardwareID
								}
								setPhoneNumber={setPhoneNumber}
								setEmail={setEmail}
								fullName={fullName}
								nickname={nickname}
								citizenship={citizenship}
								dateOfBirth={dateOfBirth}
								username={username}
								hardwareID={hardwareID}
								password={password}
								validUsername={validUsername}
								validHardwareID={validHardwareID}
								phoneNumber={phoneNumber}
								email={email}
								eyeSymbolModal={eyeSymbolModal}
								togglePasswordModal={togglePasswordModal}
								passwordShownModal={passwordShownModal}
							/>
						)}
						{render === true && (
							<SignUpForm2
								nationalIDNumber={nationalIDNumber}
								familyCardNumber={familyCardNumber}
								phoneNumber={phoneNumber}
								email={email}
								setNationalIDNumber={setNationalIDNumber}
								setFamilyCardNumber={setFamilyCardNumber}
								setPhoneNumber={setPhoneNumber}
								setEmail={setEmail}
								checkNationalIDNumber={checkNationalIDNumber}
								checkFamilyCardNumber={checkFamilyCardNumber}
								validNationalIDNumber={validNationalIDNumber}
								validFamilyCardNumber={validFamilyCardNumber}
								validFeedbackNationalIDNumber={
									validFeedbackNationalIDNumber
								}
								validFeedbackFamilyCardNumber={
									validFeedbackFamilyCardNumber
								}
							/>
						)}
					</div>
					<div className="modal-footer">
						{render === true && (
							<button
								type="button"
								className="btn btn-primary fw-bolder"
								disabled={disabled}
								onClick={() => {
									setRender(false);
								}}
							>
								<div>
									<FontAwesomeIcon
										icon={faCircleLeft}
										size="xl"
									/>
									&ensp;Back
								</div>
							</button>
						)}
						{render === false && (
							<button
								type="button"
								className="btn btn-primary fw-bolder"
								disabled={disabled}
								onClick={() => setRender(true)}
							>
								<div>
									Next&ensp;
									<FontAwesomeIcon
										icon={faCircleRight}
										size="xl"
									/>
								</div>
							</button>
						)}
						{render === true && (
							<button
								type="button"
								className="btn btn-primary fw-bolder"
								disabled={submitDisabled}
								onClick={signUp}
								data-bs-dismiss="modal"
							>
								<div>
									Submit&ensp;
									<FontAwesomeIcon
										icon={faPaperPlane}
										size="xl"
									/>
								</div>
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
