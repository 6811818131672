import React, { useState, useEffect } from "react";
import Axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { SignInForm } from "./SignInForm";
import logo from "../assets/grandpa.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import {
	faDroplet,
	faEye,
	faEyeSlash,
	faHeartPulse,
	faThermometerHalf,
} from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import "./SignIn.css";
import "../../App.css";

export const SignIn = () => {
	const [passwordShown, setPasswordShown] = useState(false);
	const [eyeSymbol, setEyeSymbol] = useState(faEyeSlash);
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [active, setActive] = useState(1);
	const [wrongPassword, setWrongPassword] = useState(false);
	const styling = (state) => {
		const style =
			active === state
				? "btn btn-dark fw-bolder border border-3"
				: "btn btn-outline-light fw-bolder border border-3";
		return style;
	};
	let navigate = useNavigate();
	const togglePassword = () => {
		setPasswordShown(!passwordShown);
		if (eyeSymbol === faEye) {
			setEyeSymbol(faEyeSlash);
		} else if (eyeSymbol === faEyeSlash) {
			setEyeSymbol(faEye);
		}
	};
	const getDeviceInfo = () => {
		const DeviceDetector = require("device-detector-js");
		const detector = new DeviceDetector();
		const platform = require("platform");
		const deviceInfo = detector.parse(platform.ua);
		return {
			brand: deviceInfo.device.brand,
			model: deviceInfo.device.model,
		};
	};
	async function handleSubmit() {
		const brand = getDeviceInfo().brand;
		const model = getDeviceInfo().model;
		Axios.post(`${process.env.REACT_APP_SERVER_IP}/api/signIn`, {
			username: username,
			password: password,
			brand: brand,
			model: model,
		}).then(async (response) => {
			if (response.data.validated === true) {
				const date = parseFloat(Date.now());
				Cookies.set("userID", response.data.userID, {
					sameSite: "lax",
				});
				Cookies.set("timeLogged", date, { sameSite: "lax" });
				Cookies.set("validated", true, { sameSite: "lax" });
				navigate("/home");
			} else {
				setWrongPassword(true);
				toast.warning("Incorrect credentials");
			}
		});
	}
	useEffect(() => {
		const user = Number(Cookies.get("userID"));
		const time = Cookies.get("timeLogged");
		const validated = Cookies.get("validated");
		const duration = Date.now() - time;
		if (user && duration < 14400000 && validated) {
			navigate("/home");
		} else {
			Cookies.remove("userID", { sameSite: "lax" });
			Cookies.remove("timeLogged", { sameSite: "lax" });
			Cookies.remove("validated", { sameSite: "lax" });
		}
	});
	return (
		<div className="container-fluid">
			<ToastContainer />
			<div className="row">
				<div className="col-md-5 p-5">
					<SignInForm
						setUsername={setUsername}
						passwordShown={passwordShown}
						setPassword={setPassword}
						togglePassword={togglePassword}
						wrongPassword={wrongPassword}
						eyeSymbol={eyeSymbol}
						password={password}
						handleSubmit={handleSubmit}
					/>
				</div>
				<div className="col-md-7 bg-primary text-white min-vh-100 container-fluid p-5 text-center">
					<div className="pt-5 d-flex flex-row justify-content-evenly">
						<div
							className={styling(1)}
							onClick={() => setActive(1)}
						>
							Home
						</div>
						<div
							className={styling(2)}
							onClick={() => setActive(2)}
						>
							About
						</div>
						<div
							className={styling(3)}
							onClick={() => setActive(3)}
						>
							Contact
						</div>
					</div>
					{active === 1 && (
						<div>
							<img
								className="img-fluid mt-5"
								src={logo}
								alt=""
								style={{ width: "400px" }}
							></img>
						</div>
					)}
					{active === 2 && (
						<div className="mt-5">
							<span className="fw-bolder">In-SWALST</span> is a
							health stick that measures someones:
							<div className="container-sm">
								<div className="mt-3">
									<FontAwesomeIcon
										icon={faHeartPulse}
										size="2xl"
									/>
									<span className="ms-2">Heart Rate</span>
								</div>
								<div className="mt-3">
									<FontAwesomeIcon
										icon={faThermometerHalf}
										size="2xl"
									/>
									<span className="ms-2">Temperature</span>
								</div>
								<div className="mt-3">
									<FontAwesomeIcon
										icon={faDroplet}
										size="2xl"
									/>
									<span className="ms-2">
										Oxygen Saturation
									</span>
								</div>
							</div>
						</div>
					)}
					{active === 3 && (
						<div className="mt-5 d-flex flex-row justify-content-center">
							<div className="container-sm">
								<div className="mt-3">
									<FontAwesomeIcon
										icon={faWhatsapp}
										size="2xl"
									/>
									<span className="ps-2 my-auto fw-bolder">
										WhatsApp
									</span>
									<div className="px-4 my-auto">
										<a
											href="https://wa.me/6289658885272"
											target="_blank"
											rel="noopener noreferrer"
										>
											(+62) 896-5888-5272
										</a>
									</div>
								</div>
								<div className="mt-3">
									<FontAwesomeIcon
										icon={faEnvelope}
										size="2xl"
									/>
									<span className="ps-2 my-auto fw-bolder">Email</span>
									<div className="px-4 my-auto">
										<a
											href="mailto:cs@inswalst.com"
											target="_blank"
											rel="noopener noreferrer"
										>
											cs@inswalst.com
										</a>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
