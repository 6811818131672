import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import axios from "axios";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faSun,
	faMoon,
	faCloudSun,
	faCloudMoon,
} from "@fortawesome/free-solid-svg-icons";
import "./Home.css";

const socket = io.connect(`${process.env.REACT_APP_SERVER_IP}:2083`, {
	withCredentials: true,
});

export const GreetingCard = () => {
	let today = new Date();
	let day = `${today.getDate() < 10 ? "0" : ""}${today.getDate()}`;
	let month = `${today.getMonth() + 1 < 10 ? "0" : ""}${
		today.getMonth() + 1
	}`;
	let year = `${today.getFullYear()}`;
	var currentHour = today.getHours();

	const [greeting, setGreeting] = useState("");
	const [icon, setIcon] = useState("");
	const [color, setColor] = useState("");
	const [ssid, setSSID] = useState("");
	const [userData, setUserData] = useState("");
	const [time, setTime] = useState("");
	const [city, setCity] = useState("");
	const [country, setCountry] = useState("");
	const [disconnected, setDisconnected] = useState(true);
	const [lat] = useState("");
	const [lng] = useState("");

	async function getUserData() {
		const userID = Number(Cookies.get("userID"));
		const response = await axios.get(
			`${process.env.REACT_APP_SERVER_IP}/api/getUserID/${userID}`
		);
		setUserData(response.data.data);
	}

	async function timeOfDay() {
		if (currentHour >= 5 && currentHour < 12) {
			setGreeting("Good Morning");
			setIcon(faCloudSun);
			setColor("#FFD700");
		} else if (currentHour >= 12 && currentHour < 17) {
			setGreeting("Good Afternoon");
			setIcon(faSun);
			setColor("#FFA500");
		} else if (currentHour >= 17 && currentHour < 21) {
			setGreeting("Good Evening");
			setIcon(faCloudMoon);
			setColor("#4169E1");
		} else if (currentHour >= 21 || currentHour < 5) {
			setGreeting("Good Night");
			setIcon(faMoon);
			setColor("#483D8B");
		}
	}

	function checkConnectivity() {
		if (lat === "" || lng === "") {
			setDisconnected(true);
		}
	}

	const apiURL = "https://api.openweathermap.org/geo/1.0/reverse?";
	const apiKey = "5a2e0466b75405ea7b0f5222c091ecf7";

	function getLocation(lat, lng) {
		if (lat && lng) {
			axios
				.get(`${apiURL}lat=${lat}&lon=${lng}&appid=${apiKey}`)
				.then((response) => {
					setCity(response.data[0]?.name);
					setCountry(response.data[0]?.country);
				});
		}
	}

	function caps(name) {
		return name?.charAt(0).toUpperCase() + name?.slice(1);
	}

	useEffect(() => {
		timeOfDay();
		checkConnectivity();
		getUserData();
		socket.on("postData", (data) => {
			const userID = Number(Cookies.get("userID"));
			if (data.hardwareID === userID) {
				setDisconnected(false);
				getLocation(data.lat, data.lng);
				setSSID(data.ssid);
			}
		});
		setInterval(() => {
			const time = new Date();
			setTime(time.toLocaleTimeString("en-GB"));
		}, 1000);
	}, []);

	return (
		<div className="card-bg w-100 border d-flex flex-column p-4">
			{!(isNaN(time || city || country) && userData?.nickname) ? (
				<div className="spinner-border" role="status">
					<span className="visually-hidden">Loading...</span>
				</div>
			) : (
				<div>
					<div className="d-flex flex-row justify-content-between">
						<div className="text-end text-dark fw-bolder card-title">
							{greeting}, {caps(userData?.nickname)}
						</div>
						<FontAwesomeIcon icon={icon} color={color} size="2xl" />
					</div>
					<div className="card-subtitle mt-4">
						{day}/{month}/{year} {time}
					</div>
					<div className="card-subtitle">
						{city}
						{country === "ID" ? ", Indonesia" : ""}
					</div>
					<div className="card-subtitle">{ssid}</div>
					{disconnected === false ? (
						<div className="card-subtitle text-end mt-3">
							In-SWALST Status :&nbsp;
							<span className="fw-bolder">ON</span>
						</div>
					) : (
						<div className="card-subtitle text-end mt-5">
							In-SWALST Status :&nbsp;
							<span className="fw-bolder">OFF</span>
						</div>
					)}
				</div>
			)}
		</div>
	);
};
