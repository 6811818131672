import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import axios from "axios";
import Cookies from "js-cookie";
import Maps from "./Maps";
import { SideNavigation } from "../Sidebar/Navigation";
import { faVolumeHigh, faVolumeXmark } from "@fortawesome/free-solid-svg-icons";
import "../../App.css";

const socket = io.connect(`${process.env.REACT_APP_SERVER_IP}:2083`, {
	withCredentials: true,
});

export const Location = () => {
	const [shake, setShake] = useState(false);
	const [state, setState] = useState(0);
	const [lat, setLat] = useState("");
	const [lng, setLng] = useState("");
	const [gpsTime, setGPSTime] = useState("");
	const [disconnected, setDisconnected] = useState(true);
	const [speaker, setSpeaker] = useState(true);

	async function getData() {
		const userID = Number(Cookies.get("userID"));
		const response = await axios.get(
			`${process.env.REACT_APP_SERVER_IP}/api/getData?userID=${userID}`
		);
		if (response.data.state === 0) {
			setState(0);
			setSpeaker(faVolumeXmark);
			setShake(false);
		} else if (response.data.state === 1) {
			setState(1);
			setSpeaker(faVolumeHigh);
			setShake(true);
		} else {
			console.log(response);
		}
	}

	function checkConnectivity() {
		if (lng === "" || lat === "") {
			setDisconnected(true);
		}
	}

	async function getGPS() {
		const userID = Number(Cookies.get("userID"));
		if (lng === "" || lat === "") {
			const response = await axios.get(
				`${process.env.REACT_APP_SERVER_IP}/api/getGPS/${userID}`
			);
			setLat(response.data.latitude);
			setLng(response.data.longitude);
			setGPSTime(response.data.updatedDateTime);
			setDisconnected(true);
		}
	}

	async function buzzer(event) {
		const userID = Number(Cookies.get("userID"));
		fetch(`${process.env.REACT_APP_SERVER_IP}/api/updateBuzzer/${userID}`, {
			headers: {
				"Content-Type": "application/json",
			},
			method: "PUT",
			body: JSON.stringify({
				state: !state,
				output: "buzzer",
			}),
		}).then([
			setShake(!shake),
			setState(!state),
			state
				? setSpeaker(faVolumeHigh)
				: setSpeaker(faVolumeXmark),
		]);
		event.stopPropagation();
	}

	useEffect(() => {
		getData();
		checkConnectivity();
		getGPS();
		socket.on("postData", (data) => {
			const userID = Number(Cookies.get("userID"));
			if (data.hardwareID === userID) {
				if (
					data.lat &&
					data.lng &&
					data.lat !== 0 &&
					data.lng !== 0
				) {
					setLat(data.lat);
					setLng(data.lng);
					setGPSTime(data.dateTime);
					setDisconnected(false);					
				} else {
					getGPS();
				}
			}
		});
	}, [shake, state, lat, lng, gpsTime, disconnected, speaker]);

	return (
		<div className="d-flex flex-row">
			<div>
				<SideNavigation></SideNavigation>
			</div>
			<div
				style={{ width: "100%", height: "100vh" }}
				className="d-flex flex-column"
			>
				<Maps
					buzzer={buzzer}
					shake={shake}
					lat={lat}
					lng={lng}
					gpsTime={gpsTime}
					disconnected={disconnected}
					speaker={speaker}
				/>
			</div>
		</div>
	);
};
