import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import {
	Menu,
	MenuItem,
	ProSidebar,
	SidebarFooter,
	SidebarHeader,
	SidebarContent,
	SubMenu,
} from "react-pro-sidebar";
import { NavLink } from "react-router-dom";
import Nickname from "./Nickname";
import SignOut from "./SignOut";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faInfo,
	faClock,
	faClockRotateLeft,
	faShieldHalved,
	faHouse,
	faAnglesRight,
	faMagnifyingGlassLocation,
	faUser,
	faNotesMedical,
} from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";
import "./Sidebar.css";

export const SideNavigation = () => {
	const [collapsed, setCollapsed] = useState(
		() => localStorage.getItem("sidebarCollapsed") === "true"
	);
	const [active, setActive] = useState(collapsed);

	const [userData, setUserData] = useState([]);

	async function getUserData() {
		const userID = Number(Cookies.get("userID"));
		const response = await axios.get(
			`${process.env.REACT_APP_SERVER_IP}/api/getUserID/${userID}`
		);
		setUserData(response.data.data);
	}

	const onClickMenuIcon = () => {
		setCollapsed((prev) => {
			const newCollapsed = !prev;
			localStorage.setItem("sidebarCollapsed", newCollapsed);
			return newCollapsed;
		});
		setActive((prev) => !prev);
	};

	useEffect(() => {
		getUserData();
	}, []);

	return (
		<ProSidebar collapsed={collapsed}>
			<SidebarHeader>
				<div style={{ margin: "20px" }}>
					{active ? (
						<div className="d-flex align-items-center justify-content-center">
							<button
								type="button"
								className="btn btn-outline-light border-3"
								onClick={onClickMenuIcon}
							>
								<FontAwesomeIcon icon={faAnglesRight} />
							</button>
						</div>
					) : (
						<Nickname
							nickname={userData?.nickname}
							onClickMenuIcon={onClickMenuIcon}
						/>
					)}
				</div>
			</SidebarHeader>
			<SidebarContent>
				<Menu iconShape="circle">
					<MenuItem
						className="sidebar-menu-item"
						icon={<FontAwesomeIcon icon={faHouse} color="white" />}
					>
						<NavLink to="/home">
							<span>Home</span>
						</NavLink>
					</MenuItem>
					<MenuItem
						className="sidebar-menu-item"
						icon={<FontAwesomeIcon icon={faUser} color="white" />}
					>
						<NavLink to="/profile">
							<span>Profile</span>
						</NavLink>
					</MenuItem>
					<SubMenu
						title="Health Data"
						className="sidebar-submenu"
						icon={
							<FontAwesomeIcon
								icon={faNotesMedical}
								color="white"
							/>
						}
					>
						<MenuItem
							className="sidebar-menu-item"
							icon={
								<FontAwesomeIcon icon={faClock} color="white" />
							}
						>
							<NavLink to="/realtime">
								<span>Real Time</span>
							</NavLink>
						</MenuItem>
						<MenuItem
							className="sidebar-menu-item"
							icon={
								<FontAwesomeIcon
									icon={faClockRotateLeft}
									color="white"
								/>
							}
						>
							<NavLink to="/history">
								<span>History</span>
							</NavLink>
						</MenuItem>
					</SubMenu>
					<MenuItem
						className="sidebar-menu-item"
						icon={
							<FontAwesomeIcon
								icon={faShieldHalved}
								color="white"
							/>
						}
					>
						<NavLink to="/security">
							<span>Security</span>
						</NavLink>
					</MenuItem>
					<MenuItem
						className="sidebar-menu-item"
						icon={
							<FontAwesomeIcon
								icon={faMagnifyingGlassLocation}
								color="white"
							/>
						}
					>
						<NavLink to="/location">
							<span>Find My In-SWALST</span>
						</NavLink>
					</MenuItem>
					<MenuItem
						className="sidebar-menu-item"
						icon={<FontAwesomeIcon icon={faInfo} color="white" />}
					>
						<NavLink to="/about">
							<span>About</span>
						</NavLink>
					</MenuItem>
				</Menu>
			</SidebarContent>
			<SidebarFooter>
				<SignOut active={active} />
			</SidebarFooter>
		</ProSidebar>
	);
};
