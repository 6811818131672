import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft } from "@fortawesome/free-solid-svg-icons";

export default function Nickname(props) {
	const { nickname, onClickMenuIcon } = props;

	function caps(nickname) {
		return nickname?.charAt(0).toUpperCase() + nickname?.slice(1);
	}

	return (
		<div className="d-flex align-items-center justify-content-between">
			{nickname === undefined ? (
				<div className="spinner-border" role="status">
					<span className="visually-hidden">Loading...</span>
				</div>
			) : (
				<div
					className="text-white fw-bolder"
					style={{ fontSize: "20px" }}
				>
					{caps(nickname)}
				</div>
			)}
			<button
				type="button"
				className="btn btn-outline-light border-3"
				onClick={onClickMenuIcon}
			>
				<FontAwesomeIcon icon={faAnglesLeft} />
			</button>
		</div>
	);
}
