import React from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import "../../App.css";

export default function SignOut({ active }) {
	let navigate = useNavigate();
	function signOut() {
		Cookies.remove("validated", { sameSite: "lax" });
		Cookies.remove("timeLogged", { sameSite: "lax" });
		Cookies.remove("userID", { sameSite: "lax" });
		navigate("/");
	}
	return (
		<div
			className="d-flex align-items-center justify-content-center"
			style={{ margin: "20px" }}
		>
			<button
				type="button"
				className="btn btn-outline-light border-3"
				onClick={signOut}
			>
				{active === true && (
					<FontAwesomeIcon icon={faRightFromBracket} />
				)}
				{active === false && (
					<div className="fw-bolder">
						<span>Sign Out&ensp;</span>
						<FontAwesomeIcon icon={faRightFromBracket} />
					</div>
				)}
			</button>
		</div>
	);
}
