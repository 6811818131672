import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSeedling } from "@fortawesome/free-solid-svg-icons";
import "./Home.css";

const socket = io.connect(`${process.env.REACT_APP_SERVER_IP}:2083`, {
	withCredentials: true,
});

export const AmbHumTemp = () => {
	const [ambTemp, setAmbTemp] = useState("");
	const [ambHum, setAmbHum] = useState("");

	useEffect(() => {
		socket.on("postData", (data) => {
			const userID = Number(Cookies.get("userID"));
			if (data.hardwareID === userID) {
				setAmbTemp(data.ambTemp);
				setAmbHum(data.ambHum);
			}
		});
	}, []);

	return (
		<div
			className="card-bg w-100 border d-flex flex-column p-4"
			style={{ gridRow: "span 1" }}
		>
			<div className="d-flex flex-row justify-content-between">
				<div className="text-end text-dark fw-bolder card-title">
					Ambient Status
				</div>
				<FontAwesomeIcon icon={faSeedling} color="green" size="2xl" />
			</div>
			<div className="card-subtitle mt-4">Temperature :</div>
			<div>
				{ambTemp ? (
					<div className="card-subtitle">
						{ambTemp ? `${ambTemp} °C ` : ""}
						{ambTemp < 20
							? `(COLD)`
							: ambTemp > 27
							? "(HOT)"
							: `(NORMAL)`}
					</div>
				) : (
					<div className="card-subtitle">Not Available</div>
				)}
			</div>
			<div className="card-subtitle mt-4">Humidity :</div>
			<div>
				{ambHum ? (
					<div className="card-subtitle">
						{ambHum ? `${ambHum} % ` : ""}
						{ambHum < 40
							? `(DRY)`
							: ambHum > 60
							? "(WET)"
							: `(NORMAL)`}
					</div>
				) : (
					<div className="card-subtitle">Not Available</div>
				)}
			</div>
		</div>
	);
};
