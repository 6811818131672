import React, { useMemo } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SignUpForm1 = (props) => {
	const {
		setFullName,
		setNickname,
		setCitizenship,
		setDateOfBirth,
		validUsername,
		validHardwareID,
		checkUsername,
		checkHardwareID,
		validFeedbackUsername,
		validFeedbackHardwareID,
		setPassword,
		fullName,
		nickname,
		citizenship,
		username,
		hardwareID,
		password,
		dateOfBirth,
		passwordShownModal,
		eyeSymbolModal,
		togglePasswordModal,
	} = props;
	const options = useMemo(() => countryList().getData(), []);
	return (
		<div>
			<div className="row">
				<div className="col-md-8">
					<div className="mb-3">
						<label htmlFor="floatingInput">Full Name</label>
						<input
							placeholder="Full Name"
							type="text"
							className="form-control"
							defaultValue={fullName}
							onChange={(e) => {
								setFullName(e.target.value);
							}}
						/>
					</div>
				</div>
				<div className="col-md-4">
					<div className="mb-3">
						<label htmlFor="floatingInput">Nickname</label>
						<input
							placeholder="Nickname"
							type="text"
							className="form-control"
							defaultValue={nickname}
							onChange={(e) => {
								setNickname(e.target.value);
							}}
						/>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-6">
					<div className="mb-3">
						<label htmlFor="floatingInput">Citizenship</label>
						<Select
							options={options}
							value={citizenship}
							onChange={setCitizenship}
						/>
					</div>
				</div>
				<div className="col-md-6">
					<div className="mb-3">
						<label htmlFor="floatingInput">Date of Birth</label>
						<input
							type="date"
							className="form-control"
							defaultValue={dateOfBirth}
							onChange={(e) => {
								setDateOfBirth(e.target.value);
							}}
						/>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<div className="mb-3">
						<label htmlFor="floatingInput">Username</label>
						<input
							placeholder="Username"
							type="text"
							className={validUsername}
							defaultValue={username}
							onChange={(e) => checkUsername(e.target.value)}
						/>
						<div className={validFeedbackUsername}>
							{validFeedbackUsername === "valid-feedback"
								? "Username available"
								: "Username taken"}
						</div>
					</div>
				</div>
				<div className="col">
					<div className="mb-3">
						<label htmlFor="floatingInput">Password</label>
						<div className="input-group">
							<input
								placeholder="Password"
								type={passwordShownModal ? "text" : "password"}
								className="form-control"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<button
								className="btn btn-primary px-3"
								type="button"
								onClick={togglePasswordModal}
							>
								<FontAwesomeIcon icon={eyeSymbolModal} />
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<div className="mb-3">
						<label htmlFor="floatingInput">Hardware ID</label>
						<input
							placeholder="Hardware ID"
							type="number"
							className={validHardwareID}
							onChange={(e) => checkHardwareID(e.target.value)}
							defaultValue={hardwareID}
						/>
						<div className={validFeedbackHardwareID}>
							{validFeedbackHardwareID === "valid-feedback"
								? "Hardware ID available"
								: "Hardware ID taken"}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
