import React from "react";
import PropTypes from "prop-types";
import { useMap } from "react-leaflet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCrosshairs,
	faMinus,
	faPlus,
} from "@fortawesome/free-solid-svg-icons";

export default function ZoomControlWithReset(props) {
	const { zoomInTitle, zoomResetTitle, zoomOutTitle, lat, lng } = props;
	const map = useMap();

	return (
		<div className="rounded border-light leaflet-control leaflet-control-zoom leaflet-bar d-flex flex-column">
			<div
				className="btn btn-primary rounded-bottom-0 rounded-top leaflet-control-zoom-in d-flex flex-row justify-content-center py-2 px-2"
				href="#"
				title={zoomInTitle}
				role="button"
				aria-label="Zoom in"
				onClick={(e) => {
					map.zoomIn();
					e.preventDefault();
				}}
			>
				<FontAwesomeIcon icon={faPlus} size="sm" color="white" />
			</div>
			<div
				className="btn btn-primary rounded-0 border-2 border-start-0 border-end-0 border-top border-bottom border-light leaflet-control-zoom-in d-flex flex-row justify-content-center py-2 px-2"
				href="#"
				title={zoomResetTitle}
				role="button"
				aria-label="Reset zoom"
				onClick={(e) => {
					map.setView([lat, lng], 20);
					e.preventDefault();
				}}
			>
				<FontAwesomeIcon icon={faCrosshairs} size="sm" color="white" />
			</div>
			<div
				className="btn btn-primary rounded-top-0 rounded-bottom leaflet-control-zoom-out d-flex flex-row justify-content-center py-2 px-2"
				href="#"
				title={zoomOutTitle}
				role="button"
				aria-label="Zoom out"
				onClick={(e) => {
					map.zoomOut();
					e.preventDefault();
				}}
			>
				<FontAwesomeIcon icon={faMinus} size="sm" color="white" />
			</div>
		</div>
	);
}

ZoomControlWithReset.propTypes = {
	zoomInTitle: PropTypes.string,
	zoomOutTitle: PropTypes.string,
	zoomResetTitle: PropTypes.string,
};

ZoomControlWithReset.defaultProps = {
	zoomInTitle: "Zoom in",
	zoomOutTitle: "Zoom out",
	zoomResetTitle: "Reset zoom",
};
