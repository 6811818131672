import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/grandpa.png";

export const PageNotFound = () => {
	let navigate = useNavigate();

	function backToHome() {
		navigate("/");
	}

	return (
		<div>
			<p className="h2 text-center mt-3 fw-bolder">Oops!</p>
			<img
				src={logo}
				alt=""
				className="my-2 mx-auto d-block"
				style={{ height: "380px" }}
			></img>
			<p className="h1 text-center fw-bolder">PAGE NOT FOUND</p>
			<p className="h5 text-center fw-bolder">
				Sorry, we can't find the page you are looking for.
			</p>
			<p className="text-center">
				You may have used an outdated link,
				<br />
				or you may have typed the address incorrectly.
			</p>
			<p className="text-center fst-italic fw-bolder">Error code: 404</p>
			<div className="d-flex justify-content-center my-3">
				<button
					type="button"
					className="btn btn-primary fw-bolder"
					onClick={backToHome}
				>
					Back To Home
				</button>
			</div>
		</div>
	);
};
