import React from "react";
import { Line } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "chart.js/auto";
import "./Card.css";

export const Cards = (props) => {
	const maximumRange = parseFloat(props.max) + 40;
	const minimumRange =
		parseFloat(props.min) - 10 < 0 ? 0 : parseFloat(props.min) - 10;
	const stepSize = parseFloat(props.stepSize);

	const options = {
		elements: {
			line: {
				tension: 0.3,
				fill: "origin",
			},
		},
		scales: {
			y: {
				max: maximumRange,
				min: minimumRange,
				ticks: {
					stepSize: stepSize,
				},
			},
		},
		pointRadius: "0",
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				labels: {
					boxWidth: 0,
					color: "rgb(256,256,256)",
				},
				title: {
					display: false,
				},
			},
		},
	};
	function setClassName() {
		if (props.third !== true) {
			return "card-bg d-flex flex-column border d-flex w-100";
		} else {
			return "card-bg d-flex flex-column border d-flex cardWidth";
		}
	}

	return (
		<div className={setClassName()} style={{ gridRow: "span 1" }}>
			<div className="p-3 d-flex flex-column">
				<div className="d-flex align-items-center justify-content-between">
					<p className="m-0 fw-bolder text-dark h5">
						{props.name} ({props.measurement})
					</p>
					<FontAwesomeIcon
						icon={props.icon}
						size="2xl"
						color={props.color}
					/>
				</div>
				<div className="mt-4 d-flex align-items-center justify-content-between card-number">
					<div className="d-flex flex-row">
						<p className="m-0 h3 fw-bolder text-dark">
							{isNaN(props.average) === true ? 0 : props.average}
						</p>
					</div>
					<div className="text-end d-flex flex-column justify-content-between">
						<div className="d-flex align-items-center justify-content-between text-primary"></div>
						<div className="d-flex align-items-center justify-content-between text-warning"></div>
					</div>
				</div>
				<div className="p-0">
					<Line height={250} data={props.data} options={options} />
				</div>
			</div>
		</div>
	);
};
