import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeartCirclePlus } from "@fortawesome/free-solid-svg-icons";
import "./Home.css";

const socket = io.connect(`${process.env.REACT_APP_SERVER_IP}:2083`, {
	withCredentials: true,
});

export const Health = () => {
	const [bpm, setBPM] = useState("");
	const [spo2, setSpO2] = useState("");
	const [temp, setTemp] = useState("");

	useEffect(() => {
		socket.on("postData", (data) => {
			const userID = Number(Cookies.get("userID"));
			if (data.hardwareID === userID) {
				setTemp(data.temp);
				setBPM(data.bpm);
				setSpO2(data.spo2);
			}
		});
	}, []);

	return (
		<div
			className="card-bg w-100 border d-flex flex-column p-4"
			style={{ gridRow: "span 1", height: "100%", width: "100vw" }}
		>
			<div className="d-flex flex-row justify-content-between">
				<div className="text-end text-dark fw-bolder card-title">
					Health Status
				</div>
				<FontAwesomeIcon
					icon={faHeartCirclePlus}
					className="my-auto"
					size="2xl"
					color="red"
				/>
			</div>
			<div className="card-subtitle mt-4">Oxygen Saturation :</div>
			<div>
				{spo2 ? (
					<div className="card-subtitle">
						{spo2 ? `${spo2} % ` : ""}
						{spo2 < 95 ? `(LOW)` : `(NORMAL)`}
					</div>
				) : (
					<div className="card-subtitle">Not Available</div>
				)}
			</div>
			<div className="card-subtitle mt-4">Temperature :</div>
			<div>
				{temp ? (
					<div className="card-subtitle">
						{temp ? `${temp} °C ` : ""}
						{temp < 36.1
							? `(LOW)`
							: temp > 37.2
							? "(HIGH)"
							: `(NORMAL)`}
					</div>
				) : (
					<div className="card-subtitle">Not Available</div>
				)}
			</div>
			<div className="card-subtitle mt-4">Heart Rate :</div>
			<div>
				{bpm ? (
					<div className="card-subtitle">
						{bpm ? `${bpm} BPM ` : ""}
						{bpm < 60 ? `(LOW)` : bpm > 100 ? `(HIGH)` : `(NORMAL)`}
					</div>
				) : (
					<div className="card-subtitle">Not Available</div>
				)}
			</div>
		</div>
	);
};
