import React from "react";
import ReactDOMServer from "react-dom/server";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import Control from "react-leaflet-custom-control";
import Leaflet from "leaflet";
import ZoomControlWithReset from "./ZoomControlWithReset";
import { RecenterAutomatically } from "./RecenterAutomatically";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import "leaflet/dist/leaflet.css";

export default function Maps({
	buzzer,
	shake,
	lat,
	lng,
	gpsTime,
	disconnected,
	speaker,
}) {
	const iconHTML = ReactDOMServer.renderToString(
		<FontAwesomeIcon
			icon={faLocationDot}
			color={disconnected ? "#00000" : "#0d6efd"}
			bounce={disconnected ? false : true}
			size="2xl"
		/>
	);
	const customMarkerIcon = new Leaflet.DivIcon({
		html: iconHTML,
		className: "dummy",
	});
	return (
		<MapContainer
			center={[lat, lng]}
			zoom={30}
			style={{ width: "100%", height: "100%" }}
			attributionControl={false}
			zoomControl={false}
		>
			<TileLayer
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>
			<Marker position={[lat, lng]} icon={customMarkerIcon}>
				<Popup>
					<div>
						Position : {lat}, {lng}
					</div>
					{disconnected ? (
						<div>
							Connection :&nbsp;
							<span className="text-decoration-underline">
								Not Active
							</span>
						</div>
					) : (
						<div>
							Last updated :&nbsp;
							<span className="text-decoration-underline">
								{gpsTime}
							</span>
						</div>
					)}
				</Popup>
			</Marker>
			<RecenterAutomatically lat={lat} lng={lng} />
			<div className="leaflet-top leaflet-right">
				<div className="rounded border-light leaflet-control leaflet-control-zoom leaflet-bar d-flex flex-column">
					<button
						type="button"
						className="btn btn-primary rounded leaflet-control-zoom-in d-flex flex-row justify-content-center py-1 px-2"
						href="#"
						aria-label="Zoom in"
					>
						<div onClick={buzzer}>
							<FontAwesomeIcon
								icon={speaker}
								shake={shake}
								size="xs"
								color="white"
							/>
						</div>
					</button>
				</div>
			</div>
			<div className="leaflet-top leaflet-left">
				<ZoomControlWithReset lat={lat} lng={lng} />
			</div>
			<Control position="bottomleft">
				{disconnected === true ? (
					<div className="d-flex flex-row">
						<div
							className="spinner-border text-danger"
							role="status"
						>
							<span className="visually-hidden">Loading...</span>
						</div>
						<p className="h6 m-auto ms-2">
							Location is&nbsp;
							<span className="text-decoration-underline">
								NOT DETECTED
							</span>
							. Last updated :&nbsp;
							<span className="fw-bolder">{gpsTime}</span>
						</p>
					</div>
				) : (
					<div></div>
				)}
			</Control>
		</MapContainer>
	);
}
