import React from "react";
import { CDBTable, CDBTableHeader, CDBTableBody } from "cdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNoteSticky } from "@fortawesome/free-solid-svg-icons";
import "./Card.css";

export const Result = (props) => {
	const { spo2, temp, bpm, time } = props;

	return (
		<div
			className="card-bg d-flex flex-column border review-margin"
			style={{ gridRow: "span 2" }}
		>
			<div className="d-flex flex-column p-0 h-100">
				<div className="mx-4 mt-3 d-flex justify-content-between align-items-center">
					<p className="fw-bolder text-dark h3 card-title">Review</p>
					<FontAwesomeIcon
						icon={faNoteSticky}
						size="2xl"
						color="#EF810E"
					/>
				</div>
				<CDBTable hover responsive>
					<CDBTableHeader>
						<tr className="text-center">
							<th className="text-center review-title">Time</th>
							<th className="text-center review-title">
								Oxygen Saturation (%SpO<sub>2</sub>)
							</th>
							<th className="text-center review-title">
								Heart Rate (BPM)
							</th>
							<th className="text-center review-title">
								Body Temperature (°C)
							</th>
						</tr>
					</CDBTableHeader>
					<CDBTableBody>
						{bpm
							.slice(0)
							.reverse()
							.filter((_,index) => index < 5)
							.map((_,index) => (
								<tr className="text-center" key={index}>
									<td className="text-center">
										{time[time.length - 1 - index]}
									</td>
									<td className="text-center">
										{spo2[spo2.length - 1 - index]}
									</td>
									<td className="text-center">
										{bpm[bpm.length - 1 - index]}
									</td>
									<td className="text-center">
										{temp[temp.length - 1 - index]}
									</td>
								</tr>
							))}
					</CDBTableBody>
				</CDBTable>
			</div>
		</div>
	);
};
