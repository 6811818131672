import React from "react";
import ReactDOMServer from "react-dom/server";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import Leaflet from "leaflet";
import { RecenterAutomatically } from "../Location/RecenterAutomatically";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import "leaflet/dist/leaflet.css";

export default function Maps({ lat, lng, disconnected }) {
	const iconHTML = ReactDOMServer.renderToString(
		<FontAwesomeIcon
			icon={faLocationDot}
			color={disconnected ? "#00000" : "#0d6efd"}
			bounce={disconnected ? false : true}
			size="2xl"
		/>
	);
	const customMarkerIcon = new Leaflet.DivIcon({
		html: iconHTML,
		className: "dummy",
	});
	return (
		<MapContainer
			center={[lat, lng]}
			zoom={30}
			style={{ width: "100%", height: "100%" }}
			attributionControl={false}
			zoomControl={false}
			closePopupOnClick={false}
			boxZoom={false}
			doubleClickZoom={false}
			dragging={false}
			trackResize={false}
			keyboard={false}
			scrollWheelZoom={false}
			touchZoom={false}
		>
			<TileLayer
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>
			<Marker position={[lat, lng]} icon={customMarkerIcon}>
				<Popup>
					<div>
						Position : {lat}, {lng}
					</div>
					<div>
						Connection :&nbsp;
						{disconnected ? (
							<span className="text-decoration-underline">
								Not Active
							</span>
						) : (
							<span className="text-decoration-underline">
								Active
							</span>
						)}
					</div>
				</Popup>
			</Marker>
			<RecenterAutomatically lat={lat} lng={lng} />
		</MapContainer>
	);
}
