import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import { SideNavigation } from "../Sidebar/Navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "chart.js/auto";
import "react-toastify/dist/ReactToastify.css";
import "../Profile/Profile.css";
import "../../App.css";
import "./Security.css";

export const Security = () => {
	const [data, setData] = useState("");
	const [oldPassword, setOldPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");

	const [eyeSymbolOld, setEyeSymbolOld] = useState(faEyeSlash);
	const [eyeSymbolNew, setEyeSymbolNew] = useState(faEyeSlash);
	const [eyeSymbolConfirm, setEyeSymbolConfirm] = useState(faEyeSlash);

	const [oldPasswordShown, setOldPasswordShown] = useState(false);
	const [newPasswordShown, setNewPasswordShown] = useState(false);
	const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

	const [deviceIndex, setDeviceIndex] = useState(0);

	const goToNextDevice = () => {
		if (deviceIndex < data.length - 1) {
			setDeviceIndex(deviceIndex + 1);
		}
	};

	const goToPreviousDevice = () => {
		if (deviceIndex > 0) {
			setDeviceIndex(deviceIndex - 1);
		}
	};

	const currentDevice = data[deviceIndex];

	function togglePassword(type) {
		if (type === "old") {
			if (eyeSymbolOld === faEye) {
				setEyeSymbolOld(faEyeSlash);
			}
			if (eyeSymbolOld === faEyeSlash) {
				setEyeSymbolOld(faEye);
			}
			setOldPasswordShown(!oldPasswordShown);
		}
		if (type === "new") {
			if (eyeSymbolNew === faEye) {
				setEyeSymbolNew(faEyeSlash);
			}
			if (eyeSymbolNew === faEyeSlash) {
				setEyeSymbolNew(faEye);
			}
			setNewPasswordShown(!newPasswordShown);
		}
		if (type === "confirm") {
			if (eyeSymbolConfirm === faEye) {
				setEyeSymbolConfirm(faEyeSlash);
			}
			if (eyeSymbolConfirm === faEyeSlash) {
				setEyeSymbolConfirm(faEye);
			}
			setConfirmPasswordShown(!confirmPasswordShown);
		}
	}
	async function getActivityDetails() {
		const userID = Number(Cookies.get("userID"));
		const response = await axios.get(
			`${process.env.REACT_APP_SERVER_IP}/api/getActivityDetails/${userID}`
		);
		setData(response.data);
	}

	function returnBrowser(browser) {
		if (browser === "Chrome") {
			return (
				<div>
					<svg
						xmlns="https://www.w3.org/TR/SVG2"
						viewBox="0 0 512 512"
						fill="#0d6efd"
					>
						<path d="M0 256C0 209.4 12.5 165.6 34.3 127.1L144.1 318.3C166 357.5 207.9 384 256 384C270.3 384 283.1 381.7 296.8 377.4L220.5 509.6C95.9 492.3 0 385.3 0 256zM365.1 321.6C377.4 302.4 384 279.1 384 256C384 217.8 367.2 183.5 340.7 160H493.4C505.4 189.6 512 222.1 512 256C512 397.4 397.4 511.1 256 512L365.1 321.6zM477.8 128H256C193.1 128 142.3 172.1 130.5 230.7L54.2 98.5C101 38.5 174 0 256 0C350.8 0 433.5 51.5 477.8 128V128zM168 256C168 207.4 207.4 168 256 168C304.6 168 344 207.4 344 256C344 304.6 304.6 344 256 344C207.4 344 168 304.6 168 256z" />
					</svg>
				</div>
			);
		} else if (browser === "Firefox") {
			return (
				<div className="img-fluid">
					<svg
						xmlns="https://www.w3.org/TR/SVG2"
						viewBox="0 0 512 512"
						fill="#0d6efd"
					>
						<path d="M130.2 127.5C130.4 127.6 130.3 127.6 130.2 127.5V127.5zM481.6 172.9C471 147.4 449.6 119.9 432.7 111.2C446.4 138.1 454.4 165 457.4 185.2C457.4 185.3 457.4 185.4 457.5 185.6C429.9 116.8 383.1 89.1 344.9 28.7C329.9 5.1 334 3.5 331.8 4.1L331.7 4.2C285 30.1 256.4 82.5 249.1 126.9C232.5 127.8 216.2 131.9 201.2 139C199.8 139.6 198.7 140.7 198.1 142C197.4 143.4 197.2 144.9 197.5 146.3C197.7 147.2 198.1 148 198.6 148.6C199.1 149.3 199.8 149.9 200.5 150.3C201.3 150.7 202.1 151 203 151.1C203.8 151.1 204.7 151 205.5 150.8L206 150.6C221.5 143.3 238.4 139.4 255.5 139.2C318.4 138.7 352.7 183.3 363.2 201.5C350.2 192.4 326.8 183.3 304.3 187.2C392.1 231.1 368.5 381.8 247 376.4C187.5 373.8 149.9 325.5 146.4 285.6C146.4 285.6 157.7 243.7 227 243.7C234.5 243.7 256 222.8 256.4 216.7C256.3 214.7 213.8 197.8 197.3 181.5C188.4 172.8 184.2 168.6 180.5 165.5C178.5 163.8 176.4 162.2 174.2 160.7C168.6 141.2 168.4 120.6 173.5 101.1C148.5 112.5 129 130.5 114.8 146.4H114.7C105 134.2 105.7 93.8 106.3 85.3C106.1 84.8 99 89 98.1 89.7C89.5 95.7 81.6 102.6 74.3 110.1C58 126.7 30.1 160.2 18.8 211.3C14.2 231.7 12 255.7 12 263.6C12 398.3 121.2 507.5 255.9 507.5C376.6 507.5 478.9 420.3 496.4 304.9C507.9 228.2 481.6 173.8 481.6 172.9z" />
					</svg>
				</div>
			);
		} else if (browser === "Edge") {
			return (
				<div className="img-fluid">
					<svg
						xmlns="https://www.w3.org/TR/SVG2"
						viewBox="0 0 512 512"
						fill="#0d6efd"
					>
						<path d="M120.1 37.4C161.1 12.2 207.7-.8 255 0C423 0 512 123.8 512 219.5C511.9 252.2 499 283.4 476.1 306.7C453.2 329.9 422.1 343.2 389.4 343.7C314.2 343.7 297.9 320.6 297.9 311.7C297.9 307.9 299.1 305.5 302.7 302.3L303.7 301.1L304.1 299.5C314.6 288 320 273.3 320 257.9C320 179.2 237.8 115.2 136 115.2C98.5 114.9 61.5 124.1 28.5 142.1C55.5 84.6 111.2 44.5 119.8 38.3C120.6 37.7 120.1 37.4 120.1 37.4V37.4zM135.7 355.5C134.3 385.5 140.3 415.5 152.1 442.7C165.7 469.1 184.8 493.7 208.6 512C149.1 500.5 97.1 468.1 59.2 422.7C21.1 376.3 0 318.4 0 257.9C0 206.7 62.4 163.5 136 163.5C172.6 162.9 208.4 174.4 237.8 196.2L234.2 197.4C182.7 215 135.7 288.1 135.7 355.5V355.5zM469.8 400L469.1 400.1C457.3 418.9 443.2 435.2 426.9 449.6C396.1 477.6 358.8 495.1 318.1 499.5C299.5 499.8 281.3 496.3 264.3 488.1C238.7 477.8 217.2 458.1 202.7 435.1C188.3 411.2 181.6 383.4 183.7 355.5C183.1 335.4 189.1 315.2 198.7 297.3C212.6 330.4 236.2 358.6 266.3 378.1C296.4 397.6 331.8 407.6 367.7 406.7C398.7 407 429.8 400 457.9 386.2L459.8 385.3C463.7 383 467.5 381.4 471.4 385.3C475.9 390.2 473.2 394.5 470.2 399.3C470 399.5 469.9 399.8 469.8 400V400z" />
					</svg>
				</div>
			);
		} else if (browser === "Safari") {
			return (
				<div className="img-fluid">
					<svg
						xmlns="https://www.w3.org/TR/SVG2"
						viewBox="0 0 512 512"
						fill="#0d6efd"
					>
						<path d="M274.7 274.7l-37.4-37.4L166 346zM256 8C119 8 8 119 8 256S119 504 256 504 504 393 504 256 393 8 256 8zM411.9 182.8l14.8-6.1A8 8 0 0 1 437.1 181h0a8 8 0 0 1 -4.3 10.5L418 197.6a8 8 0 0 1 -10.5-4.3h0A8 8 0 0 1 411.9 182.8zM314.4 94l6.1-14.8A8 8 0 0 1 331 74.9h0a8 8 0 0 1 4.3 10.5l-6.1 14.8a8 8 0 0 1 -10.5 4.3h0A8 8 0 0 1 314.4 94zM256 60h0a8 8 0 0 1 8 8V84a8 8 0 0 1 -8 8h0a8 8 0 0 1 -8-8V68A8 8 0 0 1 256 60zM181 74.9a8 8 0 0 1 10.5 4.3L197.6 94a8 8 0 1 1 -14.8 6.1l-6.1-14.8A8 8 0 0 1 181 74.9zm-63.6 42.5h0a8 8 0 0 1 11.3 0L140 128.7A8 8 0 0 1 140 140h0a8 8 0 0 1 -11.3 0l-11.3-11.3A8 8 0 0 1 117.4 117.4zM60 256h0a8 8 0 0 1 8-8H84a8 8 0 0 1 8 8h0a8 8 0 0 1 -8 8H68A8 8 0 0 1 60 256zm40.2 73.2-14.8 6.1A8 8 0 0 1 74.9 331h0a8 8 0 0 1 4.3-10.5L94 314.4a8 8 0 0 1 10.5 4.3h0A8 8 0 0 1 100.2 329.2zm4.3-136h0A8 8 0 0 1 94 197.6l-14.8-6.1A8 8 0 0 1 74.9 181h0a8 8 0 0 1 10.5-4.3l14.8 6.1A8 8 0 0 1 104.5 193.2zM197.6 418l-6.1 14.8a8 8 0 0 1 -14.8-6.1l6.1-14.8A8 8 0 1 1 197.6 418zM264 444a8 8 0 0 1 -8 8h0a8 8 0 0 1 -8-8V428a8 8 0 0 1 8-8h0a8 8 0 0 1 8 8zm67-6.9h0a8 8 0 0 1 -10.5-4.3L314.4 418a8 8 0 0 1 4.3-10.5h0a8 8 0 0 1 10.5 4.3l6.1 14.8A8 8 0 0 1 331 437.1zm63.6-42.5h0a8 8 0 0 1 -11.3 0L372 383.3A8 8 0 0 1 372 372h0a8 8 0 0 1 11.3 0l11.3 11.3A8 8 0 0 1 394.6 394.6zM286.3 286.3 110.3 401.7 225.8 225.8 401.7 110.3zM437.1 331h0a8 8 0 0 1 -10.5 4.3l-14.8-6.1a8 8 0 0 1 -4.3-10.5h0A8 8 0 0 1 418 314.4l14.8 6.1A8 8 0 0 1 437.1 331zM444 264H428a8 8 0 0 1 -8-8h0a8 8 0 0 1 8-8h16a8 8 0 0 1 8 8h0A8 8 0 0 1 444 264z" />
					</svg>
				</div>
			);
		} else if (browser === "Opera") {
			return (
				<div className="img-fluid">
					<svg
						xmlns="https://www.w3.org/TR/SVG2"
						viewBox="0 0 496 512"
						fill="#0d6efd"
					>
						<path d="M313.9 32.7c-170.2 0-252.6 223.8-147.5 355.1 36.5 45.4 88.6 75.6 147.5 75.6 36.3 0 70.3-11.1 99.4-30.4-43.8 39.2-101.9 63-165.3 63-3.9 0-8 0-11.9-.3C104.6 489.6 0 381.1 0 248 0 111 111 0 248 0h.8c63.1 .3 120.7 24.1 164.4 63.1-29-19.4-63.1-30.4-99.3-30.4zm101.8 397.7c-40.9 24.7-90.7 23.6-132-5.8 56.2-20.5 97.7-91.6 97.7-176.6 0-84.7-41.2-155.8-97.4-176.6 41.8-29.2 91.2-30.3 132.9-5 105.9 98.7 105.5 265.7-1.2 364z" />
					</svg>
				</div>
			);
		} else {
			return (
				<div className="img-fluid">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 512 512"
						fill="#0d6efd"
					>
						<path d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z" />
					</svg>
				</div>
			);
		}
	}

	async function update() {
		if (newPassword !== confirmNewPassword) {
			toast.warning("Password confirmation is not the same");
		} else {
			fetch(`${process.env.REACT_APP_SERVER_IP}/api/resetPassword`, {
				headers: {
					"Content-Type": "application/json",
				},
				method: "PUT",
				body: JSON.stringify({
					oldPassword,
					newPassword,
					userID: Number(Cookies.get("userID")),
				}),
			}).then((response) => {
				if (response.status === 404) {
					toast.error("Password is wrong");
				}
				if (response.status === 200) {
					toast.success("Succesfully changed password");
					setOldPassword("");
					setNewPassword("");
					setConfirmNewPassword("");
				}
			});
		}
	}
	useEffect(() => {
		getActivityDetails();
	}, []);
	return (
		<div className="dashboard d-flex">
			<ToastContainer />
			<div>
				<SideNavigation></SideNavigation>
			</div>
			<div
				style={{
					flex: "1 1 auto",
					display: "flex",
					flexFlow: "column",
					height: "100vh",
					overflowY: "hidden",
				}}
			>
				<div style={{ height: "100%" }}>
					<div style={{ height: "100%", overflowY: "auto" }}>
						<div className="d-flex card-section-main">
							<div className="cards-container-main">
								<div
									className="card-bg w-100 d-flex flex-column border d-flex flex-column"
									style={{ gridColumn: "span 2" }}
								>
									<div className="p-3 d-flex flex-column h-100">
										<div className="d-flex align-items-center justify-content-between">
											<p className="h2 m-0 py-4 ms-5 fw-bolder text-dark text-decoration-underline">
												Security
											</p>
										</div>
										<div className="row">
											<div className="col-md-7">
												<div className="px-5 pt-4">
													<p className="h5">
														Change Password
													</p>
													<hr className="w-100" />
													<div className="ms-2">
														Current Password
													</div>
													<div className="input-group ms-1">
														<input
															className="form-control"
															type={
																oldPasswordShown
																	? "text"
																	: "password"
															}
															placeholder="Current Password"
															value={oldPassword}
															aria-label="default input example"
															onChange={(e) =>
																setOldPassword(
																	e.target
																		.value
																)
															}
														/>
														<button
															className="btn btn-primary px-3"
															type="button"
															onClick={() =>
																togglePassword(
																	"old"
																)
															}
														>
															<FontAwesomeIcon
																icon={
																	eyeSymbolOld
																}
															/>
														</button>
													</div>
													<div className="ms-2 mt-3">
														New Password
													</div>
													<div className="input-group ms-1">
														<input
															className="form-control "
															type={
																newPasswordShown
																	? "text"
																	: "password"
															}
															placeholder="New Password"
															value={newPassword}
															aria-label="default input example"
															onChange={(e) =>
																setNewPassword(
																	e.target
																		.value
																)
															}
														/>
														<button
															className="btn btn-primary px-3"
															type="button"
															onClick={() =>
																togglePassword(
																	"new"
																)
															}
														>
															<FontAwesomeIcon
																icon={
																	eyeSymbolNew
																}
															/>
														</button>
													</div>
													<div className="ms-2 mt-3">
														Confirm New Password
													</div>
													<div className="input-group ms-1">
														<input
															className="form-control"
															type={
																confirmPasswordShown
																	? "text"
																	: "password"
															}
															placeholder="Confirm New Password"
															value={
																confirmNewPassword
															}
															aria-label="default input example"
															onChange={(e) =>
																setConfirmNewPassword(
																	e.target
																		.value
																)
															}
														/>
														<button
															className="btn btn-primary px-3"
															type="button"
															onClick={() =>
																togglePassword(
																	"confirm"
																)
															}
														>
															<FontAwesomeIcon
																icon={
																	eyeSymbolConfirm
																}
															/>
														</button>
													</div>
													<div className="d-flex justify-content-end mb-5 mt-4">
														<button
															type="button"
															className="btn btn-primary fw-bolder"
															variant="secondary"
															onClick={update}
														>
															Change Password
														</button>
													</div>
												</div>
											</div>

											<div className="col-md-5">
												<div className="margin-col-potrait">
													<p className="h5">
														Connected Device
													</p>
													<hr className="w-100" />
													{!data ? (
														<div
															className="spinner-border"
															role="status"
														>
															<span className="visually-hidden">
																Loading...
															</span>
														</div>
													) : (
														<div className="row">
															<div className="d-flex align-items-center justify-content-center">
																<div
																	className="col-md-auto img-fluid"
																	style={{
																		width: "12vw",
																	}}
																>
																	{returnBrowser(
																		currentDevice.browser
																			.split(
																				" "
																			)
																			.shift()
																	)}
																</div>
																<div className="col-md-auto ps-4">
																	<div className="d-flex flex-column">
																		{currentDevice.brand ===
																			"undetected" ||
																		!currentDevice.brand ? (
																			""
																		) : (
																			<div>
																				{
																					currentDevice.brand
																				}
																			</div>
																		)}

																		{currentDevice.model ===
																			"undetected" ||
																		!currentDevice.model ? (
																			""
																		) : (
																			<div>
																				{
																					currentDevice.model
																				}
																			</div>
																		)}
																		<div>
																			{
																				currentDevice.browser
																			}
																		</div>
																		<div>
																			{
																				currentDevice.os
																			}
																		</div>
																		<div>
																			{
																				currentDevice.ip
																			}
																		</div>
																		<div>
																			{deviceIndex ===
																			0 ? (
																				<span className="fw-bolder">
																					Active
																					Now
																				</span>
																			) : (
																				<span className="fw-bolder">
																					{
																						currentDevice.dateTime
																					}
																				</span>
																			)}
																		</div>
																	</div>
																</div>
															</div>
															<div className="d-flex justify-content-between mb-5 mt-4">
																<button
																	className="btn btn-primary fw-bolder"
																	onClick={
																		goToPreviousDevice
																	}
																	disabled={
																		deviceIndex ===
																		0
																	}
																>
																	Previous
																</button>
																<button
																	className="btn btn-primary fw-bolder"
																	onClick={
																		goToNextDevice
																	}
																	disabled={
																		deviceIndex ===
																		data.length -
																			1
																	}
																>
																	Next
																</button>
															</div>
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
